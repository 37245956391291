import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Component, ViewChild } from '@angular/core';
import { ProcessamentoDocumentosService } from './processamento-documentos.service';
import { interval, throwError } from 'rxjs';
import { AuthService } from '../core/auth/service/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from '../shared/shared-data/shared-data.service';
import { ProcessamentoDocumentos } from '../modelo/ProcessamentoDocumentos';

type DataType = { label: string, value: string }

@Component({
  selector: 'app-processamento-documentos',
  templateUrl: './processamento-documentos.component.html',
  styleUrls: ['./processamento-documentos.component.css'],
  providers: [MessageService, ConfirmationService, FileUploadModule],
})
export class ProcessamentoDocumentosComponent {

  loading: boolean = false;
  lifeMessageToast: number = 5000;

  processamentos: ProcessamentoDocumentos[] = [];

  processamentoListaId: String | undefined | null;
    
  constructor(
    private messageService: MessageService,
    private service: ProcessamentoDocumentosService,
    private sharedData: SharedDataService,
    private auth: AuthService
    ) {}

  async ngOnInit() {
    this.processamentoListaId = this.sharedData.getProcessamentoLista();
    await this.fetchContent(); 
      interval(10000) // Atualiza a cada 1 minuto
      .subscribe(() => {
      this.fetchContent();
        }
      );
  }

  getIconClass(status: string): String | undefined | null {
    if (status == 'CONCLUIDO')
      return 'pi pi-check p-button-success';
    if (status == 'FALHA')
      return 'pi pi-times p-button-danger';
    if (status == 'AVISOS')
      return 'pi pi-exclamation-triangle p-button-warning';
    if (status == 'PENDENTE')
      return 'pi pi-stopwatch p-button-help';
    if (status == 'EM_ANDAMENTO')
      return 'pi pi-sync pi-spin p-button-info';  
    return '';
  }  

  public fetchContent(): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getDocumentos(this.processamentoListaId).subscribe({
      next: (payload: ProcessamentoDocumentos[]) => {
        this.processamentos = payload;
    },

      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Documentos',
          detail: 'Erro ao tentar buscar lista de documentos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }



}