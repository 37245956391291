import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
  })

export class DashboardService {

    apiUrl: string = `${environment.backendUrl}/dashboard`;

    httpOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJHcnVwbyBkZSBUZXN0ZSIsImlzcyI6InVzdWFyaW8udGVzdGUiLCJpYXQiOjE2OTYwMDkzMDYsImV4cCI6MTcwMzg3MTcwNn0.WSfSAzxJZ2UsDOQRwZsnf2F_g5ND8sd10ttfH4uFGYDvzEwh78OsK0cJZ2awjE44qH46D8LWfj5BuyBM168zAg'
        }
    }

    constructor(private http: HttpClient) { }

    getQuantidadeProcessosPorMes(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/quantidade/processos`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getDocumentosPorMes(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/quantidade/documentos`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getRegistrosPorMes(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/quantidade/registros`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getRegistrosPorStatusMesAnterior(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/registros/status/anterior`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getRegistroPorStatusMesAtual(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/registros/status/atual`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getColunasMaisAtualizadasMesAtual(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/colunas/atualizados/atual`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getColunasMaisAtualizadasMesAnterior(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/colunas/atualizados/anterior`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getDocumentosPorStatusMesAtual(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/documentos/status/atual`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

    getDocumentosPorStatusMesAnterior(): any {
        let searchParams = new HttpParams();
        return this.http.get<string[]>(`${this.apiUrl}/documentos/status/anterior`,
          { params: searchParams, headers: this.httpOptions.headers });
    }

}