<app-menu-bar></app-menu-bar>

<p-panel header="Procedimentos Iniciais" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar a plataforma">
            <tr>
                <td>
                    <img src="../../assets/images/PaginaInicial.jpg" class="ImagemPadrao">
                </td>
                <td>
                    <p>
                        Para acessar a plataforma, devera acessar atraves de um navegador (browser) o endereco https://carga-eurofarma.vetoritsolucoes.com.br.
                    </p>
                </td>
            </tr>
        </p-tabPanel>
        <p-tabPanel header="Verificando a versao da aplicacao">
            <td>
                <img src="../../assets/images/VerificandoVersao.jpg" class="ImagemPadrao">             
            </td>
            <td>
                <p>
                    Na tela inicial (tela de login), no canto inferior a direita, e possivel visualizar a versao da aplicacao (incluindo as versoes dos componentes do produto).
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Efetuar login">
            <td>
                <img src="../../assets/images/PaginaInicial.jpg" class="ImagemPadrao"><BR>
            </td>
            <td>
                <p>
                    Para efetuar o login na aplicacao, devera ser informado o usuario e a senha.
                </p>
                <p>
                    Apos o preenchimento dessas informacoes, clicar no botao Entrar.
                </p>
                <p>
                    Caso o login seja efetuado com sucesso, automaticamente sera redirecionado para a tela de processamento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Menus disponiveis">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao"><BR>
            </td>
            <td>
                Uma vez que seja feito o login na aplicacao, estarao disponiveis os menus abaixo:<BR>
                <ol>
                    <li>Grupos - atraves desse menu o usuario podera consultar os dados do grupo a que pertence. Tambem sera possivel fazer alguma manutencao dos dados</li>
                    <li>Usuarios - atraves desse menu o usuario podera fazer a manutencao de usuarios, compreendendo a inclusao, alteracao e exclusao de usuarios.</li>
                    <li>Processamento - atraves desse menu o usuario podera acompanhar os processamentos em execucao, submeter um novo processamento e tambem consultar processamentos executados anteriormente.</li>
                    <li>Abrir novo ticket - atraves desse menu o usuario podera realizar a abertura de um ticket para reportar eventualmente uma ocorrência na aplicacao. So devem ser abertos ticket em se tratando de erros de aplicacao. Problemas com relacao ao dados processados, devem ser ajustados na planinha e submetidos a um novo processamento.</li>
                    <li>Ajuda - atraves desse menu o usuario podera esclarecer duvidas sobre o funcioanmento do produto.</li>
                </ol>            
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Subindo uma nova carga" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Atraves do menu principal, acessar o menu denominado Processamento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Clicar no botao Upload">
            <td>
                <img src="../../assets/images/BotaoUpload.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Após a abertura da janela de prrocessamento, clicar no botao Upload.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Selecionar os arquivos desejados">
            <td>
                    Clique no botao Selecionar Arquivos para a abertura da janela de escolha dos arquivos.<br>
                    <img src="../../assets/images/SelecionarArquivos.jpg" class="ImagemPadrao">
            </td>
            <td>
                    Selecione os arquivos que desejam ser carregados. Podem ser selecionados mais de um arquivo ao mesmo tempo.<br>
                    <img src="../../assets/images/ListaArquivos.jpg" class="ImagemPadrao">
                    Lembrando que o arquivo a ser processado deve ser uma planilha Excel no layout pre-formatado passado anteriormente. Em caso de duvidas, consultar o topico referente ao layout pre-definido.    
            </td>
        </p-tabPanel>
        <p-tabPanel header="Clicar em enviar">
            <td>
                <img src="../../assets/images/EnviarArquivos.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Apos a selecao dos arquivos que deverao ser processados. Clicar no botao Enviar.
                </p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Acompanhando a evoluçao do processamento" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Atraves do menu principal, acessar o menu denominado Processamento.</p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Listagem de Processos">
            <td>
                <img src="../../assets/images/ListaProcessamento.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Dentro da lista de processamentos disponiveis, localizar o processo desejado.</p>
                <p>Clicar no botao Detalhar para apresentar maiores informacoes desse processamento.</p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Listagem de Listas (abas)">
            <td>
                <img src="../../assets/images/ListagemListas.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Nessa listagem sao apresentados todas as listas contidas no arquivo selecionado previamente.</p>
                <p>Nesse detalhamento e possivel verificar o status de processamento da lista, incluindo o tratamento de cada linha da planilha, bem como o processamento dos documentos.</p>
                <p>Caso tenha a necessidade de avaliar os documentos processados, e possivel clicando no botao Detalhar.</p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Detalhamento dos Documentos">
            <td>
                <img src="../../assets/images/DetalhamentoDocumentos.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Nessa listagem sao apresentados todos os documentos processados e seu respectivo status.</p>
                <p>Quando um documento apresenta falhas, o seu status e apresentado como Falhas ou Avisos, e ao lado a ocorrencia.</p>
                <p>Em se tratando de problema de dados, deve-se elaborar uma nova planilha com a correcao do respectivo problema.</p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>


<p-panel header="Exportando os erros do processamento de uma lista" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Atraves do menu principal, acessar o menu denominado Processamento.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Selecionar e detalhar o processamento">
            <td>
                <img src="../../assets/images/ListaProcessamento.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Dentro da lista de processamentos disponiveis, localizar o processo desejado.</p>
                <p>Clicar no botao Detalhar para apresentar maiores informacoes desse processamento.</p>
            </td>        
        </p-tabPanel>
        <p-tabPanel header="Localizar a lista que se desejar exportar">
        <td>
            <img src="../../assets/images/ListagemListas.jpg" class="ImagemPadrao">
        </td>
        <td>
            <p>Nessa listagem sao apresentados todas as listas contidas no arquivo selecionado previamente.</p>
            <p>Nesse detalhamento e possivel verificar o status de processamento da lista, incluindo o tratamento de cada linha da planilha, bem como o processamento dos documentos.</p>
            <p>Para exportar os erros dessa lista de processamento, deve-se na linha da lista clicar no botao Exportar Erros.</p>
            <p>automaticamente sera gerado um Excel com a relaçao de erros encontrados nos documentos selecionados.</p>
        </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Filtrando os dados apresentados na janela" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Processamento">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Atraves do menu principal, acessar o menu denominado Processamento.</p>
                <p>No exemplo apresentado sera usado a tela de processamento, todavia o mesmo procedimentos e aplicavel a qualquer tela do portal.</p>             
            </td>
        </p-tabPanel>
        <p-tabPanel header="Escolher a coluna que se desejar filtrar">
            <td>
                <img src="../../assets/images/FiltrarColunas1.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Ao escolher a coluna que se deseja filtrar, bastar clicar no funil para que seja apresentado o quaadro de definiçao do filtro.</p>
                <p>Proximo dessa opcao, tambem e possivel mudar a ordenacao que esta sendo apresentado na tela, clicando diretamente nas setas para cima e para baixo.</p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Preencher os dados do filtro">
            <td>
                <img src="../../assets/images/FiltrarColunas2.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>Preencher o valor que se deseja considerar no filtro. Adicionalmente as condicoes, exemplo "contenha", "igual a", "iniciado com" e assim por diante.</p>
                <p>Apos definido os argumentos para o filtro, clicar no botao Apply</p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Liberando acesso para novos profissionais" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar a manutenção de Usuários">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Através do menu principal, acessar o menu denominado Usuario.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Listagem de Usuários">
            <td>
                <img src="../../assets/images/ManutencaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Nessa janela, para iniciar a inclusão de um novo usuário, deve-se clicar no botão Adicionar.
                </p>    
            </td>
        </p-tabPanel>
        <p-tabPanel header="Preencher as informacoes do novo usuario">
            <td>
                <img src="../../assets/images/InclusaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Nessa janela, o usuário conseguirá fazer o preenchimento das informações do usuário.
                </p>    
                <p>
                    É obrigatório o preenchimento dos campos: Nome Usuário, Senha, Status, Acesso, Grupo e Email.
                </p>
            </td>
        </p-tabPanel>
        <p-tabPanel header="Gravando o usuário">
            <td>
                <img src="../../assets/images/InclusaoUsuario.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Após o preenchimento dos campos, clicar no botão Salvar.
                </p>    
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Alterando a senha de acesso" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu Usuario">
            <td>                
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Atraves do menu principal, acessar o menu denominado Usuario.
                </p>
            </td>        
        </p-tabPanel>
        <p-tabPanel header="Localizar o usuario que se deseja atualizar">
            <td>                
                <img src="../../assets/images/ManutencaoAlteracaoUsuario.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Localizar o usuário que se deseja alterar. 
                </p>
                <p>
                    No final da linha, no quadro Operações, clicar no primeiro botão para Editar o usuário.
                </p>
            </td>        
        </p-tabPanel>
        <p-tabPanel header="Alterar a informacao da senha">
            <td>                
                <img src="../../assets/images/AlteraocaoUsuario.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Ao editar um usuário, é possível alterar as suas informações. 
                </p>
                <p>
                    Nessa tela, é possível alterar a senha do usuário. É recomendado a alteração da senha inicial.
                </p>
                <p>
                    Ao término das alterações necessárias, clicar no botão Atualizar.
                </p>
            </td>        
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Abrindo um ticket para sinalizar uma ocorrência no produto" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Acessar o menu  Abrir novo ticket">
            <td>
                <img src="../../assets/images/Menus.jpg" class="ImagemPadrao">        
            </td>
            <td>
                <p>
                    Atraves do menu principal, acessar o menu denominado Abrir Novo Ticket (Ocorrência).
                </p>
                <p>
                    Tambem e possivel acessar diretamente a abertura de ticket atraves do link abaixo:<BR>
                    <a href="https://forms.monday.com/forms/f1fb6c43e3cba37ecb209aeb15df2dc1?r=use1">link</a>
                </p>
                &nbsp;
            </td>        
        </p-tabPanel>
        <p-tabPanel header="Preencher os dados da Ocorrência">
            <td>
                <img src="../../assets/images/AberturaTicket.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>Preencher os dados referente a ocorrência. Devera ser inclusas todas as informacoes relevantes para analise e diagnostico do problema.</p>
                <p>Ao termino do preenchimento, clicar no botao Submit para a abertura do ticket.</p>
                <p>Atraves do email informado, poderemos entrar em contato para esclarecimentos da ocorrência aberta, caso nao seja suficiente simular o problema.</p>
                <p>Obs.: Nao deverao ser abertos tickets para problemas de dados. Para esses casos, deve-se criar uma nova planilha com os dados a serem corrigidos e re-enviar pelo processamento.</p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>

<p-panel header="Planilha com layout pre-definida" [toggleable]="true" [collapsed]="true">
    <p-tabView>
        <p-tabPanel header="Definicoes Gerais da planilha">
            <td>
                <img src="../../assets/images/PlanilhaModelo.jpg" class="ImagemPadrao">
            </td>
            <td>
                <p>
                    Ao lado temos a planilha modelo utilizada para a carga de dados. Atualmente estamos na versão 2.1.
                </p>
                <p>
                    Não deverão ser alterados as 6 primeiras linhas da planilha. Pois são utilizados com base de validação do layout.
                </p>
                <p>
                    Os campos 'Empresa', 'Filial', 'Codigo Referencia Integracao/ Docnum' e 'Sequencia do Item' são usados para localizar no TCO o documento/item que precisa ser alterado.
                </p>
                <p>
                    Os demais campos devem ser preenchidos quando se deseja alterar o respectivo valor. Quando não for alterado determinada coluna deixar a célula preenchida com N/A.
                </p>
            </td>
        </p-tabPanel>
    </p-tabView>  
</p-panel>
