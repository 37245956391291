<app-menu-bar></app-menu-bar>
<div class="processamento-container">
    <div style="text-align: center">Processamento dos Documentos</div>
       
    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="processamentos"
        [paginator]="true"
        [rows]="20"
        [rowsPerPageOptions]="[20, 50, 100]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"  
        [globalFilterFields]="[
          'dados.id',
          'dados.processamentoLista.id',
          'dados.empresa',
          'dados.estabelecimento',
          'dados.codigoReferenciaIntegracao',
          'dados.sequenciaItem',
          'dados.etapa',
          'dados.statusProcessamento',
          'dados.mensagemAviso',
          'dados.valorAuxiliar', ]">


            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Id
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="id"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="id"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>
            
                      <th pSortableColumn="processamentoLista.id">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Processamento Lista
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="processamentoLista.id"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="processamentoLista.id"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="empresa">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Empresa
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="empresa"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="empresa"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="estabelecimento">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Estabelecimento
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="estabelecimento"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="estabelecimento"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="codigoReferenciaIntegracao">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Código Integração
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="codigoReferenciaIntegracao"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="codigoReferenciaIntegracao"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="sequenciaItem">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Sequencia Item
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="sequenciaItem"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="sequenciaItem"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>                      

                      <th pSortableColumn="etapa">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Etapa
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="etapa"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="etapa"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="statusProcessamento">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Status
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="statusProcessamento"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="statusProcessamento"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="mensagemAviso">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Mensagem de Aviso
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="mensagemAviso"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="mensagemAviso"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="valorAuxiliar">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Valor Auxiliar
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="valorAuxiliar"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="valorAuxiliar"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                </tr>
                
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>  
                    <td style="text-align: center">{{ dados.id }}</td>
                    <td style="text-align: center">{{ dados.processamentoLista.nomeLista }}</td> 
                    <td style="text-align: center">{{ dados.empresa }}</td>
                    <td style="text-align: center">{{ dados.estabelecimento}}</td>
                    <td style="text-align: center">{{ dados.codigoReferenciaIntegracao }}</td>
                    <td style="text-align: center">{{ dados.sequenciaItem }}</td>
                    <td style="text-align: center">{{ dados.etapa }}</td>
                    <td><i [class]="getIconClass(dados.statusProcessamento)"></i>{{ dados.statusProcessamento }}</td>
                    <td style="text-align: center">{{ dados.mensagemAviso }}</td>
                    <td style="text-align: center">{{ dados.valorAuxiliar }}</td>

                </tr>
        
            </ng-template>
        </p-table>

    </div>

