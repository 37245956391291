<app-menu-bar></app-menu-bar>
<div class="processamento-container">
    <div style="text-align: center">Processamento de Carga</div>
    <p-toolbar>
      <ng-template pTemplate="right">
        <div class="card flex justify-content-center">
          <p-toast></p-toast>
        </div>
        <button
          pButton
          pRipple
          icon="pi pi-plus"
          label="Upload"
          class="p-button-info mr-2"
          (click)="upload()"
        ></button>
      </ng-template>
    </p-toolbar>
  
    <div class="table-container">
        <p-table
        styleClass="p-datatable-gridlines"
        [tableStyle]="{ 'min-width': '50rem' }"
        styleClass="p-datatable-gridlines p-datatable-striped p-datatable-sm"
        [value]="processamentos"
        [paginator]="true"
        [rows]="20"
        [rowsPerPageOptions]="[20, 50, 100]"
        [showCurrentPageReport]="true"
        currentPageReportTemplate="{first} até {last} (Total de Registros: {totalRecords})"  
        [globalFilterFields]="[
          'dados.id',
          'dados.uuid',
          'dados.nomeArquivo',
          'dados.dataInicio',
          'dados.dataTermino',
          'dados.statusLeituraArquivo',
          'dados.statusLeituraExcel',
          'dados.statusProcessaListas',
          'dados.quantidadeListas']">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Id
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="id"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="id"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>
            
                      <th pSortableColumn="uuid">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            UUID
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="uuid"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="uuid"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="nomeArquivo">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Nome do Arquivo
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="nomeArquivo"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="nomeArquivo"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="dataInicio">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Início
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataInicio"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="dataInicio"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th pSortableColumn="dataTermino">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Termino
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="dataTermino"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="dataTermino"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>
                      <th>Status</th>
                      <th pSortableColumn="quantidadeListas">
                        <div class="flex justify-content-between align-items-center">
                          <div class="flex justify-content-between align-items-center">
                            Quantidade Lista
                          </div>
                          <div class="flex justify-content-between align-items-center">
                            <p-sortIcon field="quantidadeListas"></p-sortIcon>
                            <p-columnFilter
                              type="text"
                              field="quantidadeListas"
                              display="menu"
                              matchMode="contains"
                              [showMatchModes]="true"
                              [showOperator]="true"
                              [showAddButton]="true"
                            ></p-columnFilter>
                          </div>
                        </div>
                      </th>

                      <th style="text-align: center">Operação</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dados>
                <tr>  
                    <td style="text-align: center">{{ dados.id }}</td>
                    <td style="text-align: center">{{ dados.uuid }}</td> 
                    <td style="text-align: center">{{ dados.nomeArquivo }}</td>
                    <td style="text-align: center">{{ dados.dataInicio | date : "yyyy-MM-dd HH:mm:ss" }}</td>
                    <td style="text-align: center">{{ dados.dataTermino | date : "yyyy-MM-dd HH:mm:ss" }}</td>
                    <td>
                        <i [class]="getIconClass(dados.statusLeituraArquivo)"></i>&nbsp;Leitura Arquivo<BR>
                        <i [class]="getIconClass(dados.statusLeituraExcel)"></i>&nbsp;Leitura Excel<BR>
                        <i [class]="getIconClass(dados.statusProcessaListas)"></i>&nbsp;Processa Lista<BR>
                    </td>
                    <td style="text-align: center">{{ dados.quantidadeListas }}</td>
                    <td style="text-align: center">
                      <i class="pi pi-external-link" style="color: blue" pTooltip="Detalhar" tooltipPosition="left" (click)="detalhaArquivo(dados)">
                        Detalhar
                      </i>
                    </td>
                </tr>
        
            </ng-template>
        </p-table>

    </div>








    <!-- Dialog de inserir anexo -->
    <p-dialog
      header="Upload de nova carga"
      [(visible)]="uploadDialog"
      [modal]="true"
      styleClass="p-fluid">
      <ng-template pTemplate="content">
        <div>
            <p-toast></p-toast>
            <div class="upload-container">
                <p-fileUpload name="file[]"
                    [customUpload]="true"
                    (uploadHandler)="uploadFile($event)"
                    (onUpload)="uploadFile($event)"
                    [multiple]="true"
                    accept
                    [maxFileSize]="10000000"
                    chooseLabel="Selecionar Arquivo"
                    cancelLabel="Cancelar"
                    uploadLabel="Enviar"
                    uploadIcon="pi pi-cloud-upload">
                </p-fileUpload>
            </div>
        </div>
      </ng-template>
    </p-dialog>
</div>