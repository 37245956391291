import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { AuthService } from '../core/auth/service/auth.service';
import { Router } from '@angular/router';
import { MenuService } from './menu-bar.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-menu-bar',
  templateUrl: './menu-bar.component.html',
  styleUrls: ['./menu-bar.component.css']
})
export class MenuBarComponent implements OnInit{
  items: MenuItem[]= [];
  submenus: MenuItem[] = [];
  usuarioLogado: string | null;
  exibeRegras: boolean = true;

  constructor(
  private auth: AuthService,
  private router: Router,
  private service: MenuService,
  private cdr: ChangeDetectorRef) {
    this.usuarioLogado = this.auth.obterUsuarioLogado();
  }

  async ngOnInit() {
    this.submenus.push({
      label: 'Dashboard',
      icon: 'pi pi-chart-line',
      url: 'dashboard'
    });
    this.submenus.push({
      label: "Grupos",
      icon: 'pi pi-users',
      url: 'manutencao-grupo'
    });
    this.submenus.push({
      label: "Usuários",
      icon: 'pi pi-user',
      url: 'manutencao-usuario'
    });
    this.submenus.push({
      label: "Processamento",
      icon: 'pi pi-desktop',
      url: 'manutencao-processamento'
    });
    this.submenus.push({
      label: "Abrir novo ticket (Ocorrência)",
      icon: 'pi pi-exclamation-triangle',
      command: () => {
          window.open('https://forms.monday.com/forms/f1fb6c43e3cba37ecb209aeb15df2dc1?r=use1', '_blank');
      }
    });
    this.submenus.push({
      label: "Ajuda",
      icon: 'pi pi-question-circle',
      url: 'ajuda'
    });


    
    this.items = [{
        label: "",
        icon: 'pi pi-bars',
        items: this.submenus
      }]    
    
  }

  logout() {
    this.auth.destroySession();
    this.router.navigateByUrl('login');
  }
}
