import { AuthService } from '../core/auth/service/auth.service';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DashboardService } from './dashboard.service';
import { FileUploadModule } from 'primeng/fileupload';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    providers: [MessageService, ConfirmationService, FileUploadModule],
  })
  
  export class DashboardComponent {

    constructor(
        private auth: AuthService,
        private messageService: MessageService,
        private service: DashboardService) {
    }

    lifeMessageToast: number = 7000;

    resumoQuantidadeProcessosPorMes!: ResumoDashboard;
    resumoColunasMaisAtualizadasMesAnterior!: ResumoDashboard;
    resumoDocumentosPorMes!: ResumoDashboard;
    resumoRegistrosPorMes!: ResumoDashboard;
    resumoRegistrosPorStatusMesAnterior!: ResumoDashboard;
    resumoRegistroPorStatusMesAtual!: ResumoDashboard;
    resumoColunasMaisAtualizadasMesAtual!: ResumoDashboard;
    resumoDocumentosPorStatusMesAtual!: ResumoDashboard;
    resumoDocumentosPorStatusMesAnterior!: ResumoDashboard;

    scales = {
        x: {
          display: true, 
          title: {
              display: true, 
              text: 'Mês'}
          },
        y: {
          display: true, 
          title: {
              display: true, 
              text: 'Quantidade'}
          }
      };

    legend = { 
            display: true, 
            position: 'right'
    };

    colunasMesAnteriorData = {};
    colunasMesAtualData = {};
    qtdCargaMesData = {};
    qtdRegistrosMesData = {};
    qtdDocumentosMesData = {};
    regStatusMesAnteriorData = {};
    regStatusMesAtualData = {};
    docStatusAnteriorData = {};
    docStatusAtualData = {};

    regStatusOptions = {
        plugins: {
            legend: { 
                display: false},
            title: {
                display: true,
                text: 'Registros por Status'
            }
        },
        scales: this.scales 
    };

    docStatusOptions = {
        plugins: {
            legend: { 
                display: false},
            title: {
                display: true,
                text: 'Registros por Status'
            }
        },
        scales: this.scales        
    }

    colunasMesOptions = {
        plugins: {
            legend: { 
                display: false},
            title: {
                display: true,
                text: 'Colunas Mais Utilizadas'
            }
        },
        scales: this.scales 
    };

    qtdCargaMesOptions = {
        plugins: {
            legend: { 
                display: false},
            title: {
                display: true,
                text: 'Quantidade de Cargas por Mes'
            }
        },
        scales: this.scales 
    };

    qtdRegistrosMesOptions = {
        plugins: {
            legend: { 
                display: false},
            title: {
                display: true,
                text: 'Quantidade de Registros Atualizados por Mes'
            }
        },
        scales: this.scales 
    };

    qtdDocumentosMesOptions = {
        plugins: {
            legend: { 
                display: false},
            title: {
                display: true,
                text: 'Quantidade de Documentos Atualizados por Mes'
            }
        },
        scales: this.scales 
    }

    async ngOnInit() {
        await this.fetchContent();
    }

    getColunasMaisAtualizadasMesAnterior() {
        this.service.getColunasMaisAtualizadasMesAnterior().subscribe(
            (response: any) => {
                this.resumoColunasMaisAtualizadasMesAnterior = response;   
                this.colunasMesAnteriorData = { labels: this.resumoColunasMaisAtualizadasMesAnterior.labels,
                    datasets: [{   data: this.resumoColunasMaisAtualizadasMesAnterior.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};             
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getQuantidadeProcessosPorMes() {
        this.service.getQuantidadeProcessosPorMes().subscribe(
            (response: any) => {
                this.resumoQuantidadeProcessosPorMes = response;
                this.qtdCargaMesData = { labels: this.resumoQuantidadeProcessosPorMes.labels,
                    datasets: [{   data: this.resumoQuantidadeProcessosPorMes.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getDocumentosPorMes() {
        this.service.getDocumentosPorMes().subscribe(
            (response: any) => {
                this.resumoDocumentosPorMes = response;
                this.qtdDocumentosMesData = { labels: this.resumoDocumentosPorMes.labels,
                    datasets: [{   data: this.resumoDocumentosPorMes.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getRegistrosPorMes() {
        this.service.getRegistrosPorMes().subscribe(
            (response: any) => {
                this.resumoRegistrosPorMes = response;
                this.qtdRegistrosMesData = { labels: this.resumoRegistrosPorMes.labels,
                    datasets: [{   data: this.resumoRegistrosPorMes.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};

            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getRegistrosPorStatusMesAnterior() {
        this.service.getRegistrosPorStatusMesAnterior().subscribe(
            (response: any) => {
                this.resumoRegistrosPorStatusMesAnterior = response;
                this.regStatusMesAnteriorData = { labels: this.resumoRegistrosPorStatusMesAnterior.labels,
                    datasets: [{   data: this.resumoRegistrosPorStatusMesAnterior.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getRegistroPorStatusMesAtual() {
        this.service.getRegistroPorStatusMesAtual().subscribe(
            (response: any) => {
                this.resumoRegistroPorStatusMesAtual = response;
                this.regStatusMesAtualData = { labels: this.resumoRegistroPorStatusMesAtual.labels,
                    datasets: [{   data: this.resumoRegistroPorStatusMesAtual.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getColunasMaisAtualizadasMesAtual() {
        this.service.getColunasMaisAtualizadasMesAtual().subscribe(
            (response: any) => {
                this.resumoColunasMaisAtualizadasMesAtual = response;
                this.colunasMesAtualData = { labels: this.resumoColunasMaisAtualizadasMesAtual.labels,
                    datasets: [{   data: this.resumoColunasMaisAtualizadasMesAtual.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getDocumentosPorStatusMesAtual() {
        this.service.getDocumentosPorStatusMesAtual().subscribe(
            (response: any) => {
                this.resumoDocumentosPorStatusMesAtual = response;
                this.docStatusAtualData = { labels: this.resumoDocumentosPorStatusMesAtual.labels,
                    datasets: [{   data: this.resumoDocumentosPorStatusMesAtual.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    getDocumentosPorStatusMesAnterior() {
        this.service.getDocumentosPorStatusMesAnterior().subscribe(
            (response: any) => {
                this.resumoDocumentosPorStatusMesAnterior = response;
                this.docStatusAnteriorData = { labels: this.resumoDocumentosPorStatusMesAnterior.labels,
                    datasets: [{   data: this.resumoDocumentosPorStatusMesAnterior.datasets[0].data,
                      borderColor: 'gray', backgroundColor:["#FFFF00","#FFE200","#FFC600","#FFAA00","#FF8D00","#FF7100","#FF5500","#FF3800","#FF1C00","#FF0000"],
                      borderWidth: 2, borderSkipped: false }]};
            },
            (error: any) => {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Dashboard',
                    detail: 'Erro ao tentar buscar dados de dashboard!',
                    life: this.lifeMessageToast,
                  });
            }
        );
    }

    public fetchContent(): void {
        if (!this.auth.isAuthenticated())
          return;
        this.getColunasMaisAtualizadasMesAnterior();
        this.getQuantidadeProcessosPorMes();
        this.getDocumentosPorMes();
        this.getRegistrosPorMes();
        this.getRegistrosPorStatusMesAnterior();
        this.getRegistroPorStatusMesAtual();
        this.getColunasMaisAtualizadasMesAtual();
        this.getDocumentosPorStatusMesAtual();
        this.getDocumentosPorStatusMesAnterior();
    }
  }

interface Dataset {
    label: string;  // Permite strings ou null
    data: number[];        // Array de números
}

interface ResumoDashboard {
    labels: string[];
    datasets: Dataset[];
}