import { ConfirmationService, MessageService } from 'primeng/api';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { Component, ViewChild } from '@angular/core';
import { ProcessamentoService } from './manutencao-processamento.service';
import { ProcessamentoArquivo } from '../modelo/Processamento';
import { interval, throwError } from 'rxjs';
import { AuthService } from '../core/auth/service/auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SharedDataService } from '../shared/shared-data/shared-data.service';

type DataType = { label: string, value: string }

@Component({
  selector: 'app-manutencao-processamento',
  templateUrl: './manutencao-processamento.component.html',
  styleUrls: ['./manutencao-processamento.component.css'],
  providers: [MessageService, ConfirmationService, FileUploadModule],
})

export class ManutencaoProcessamentoComponent {
  @ViewChild(FileUpload) fileUpload!: FileUpload;

  uploadDialog: boolean = false;
  loading: boolean = false;
  lifeMessageToast: number = 5000;

  processamentos: ProcessamentoArquivo[] = [];
    
  constructor(
    private messageService: MessageService,
    private service: ProcessamentoService,
    private sharedData: SharedDataService,
    private auth: AuthService
    ) {}

  async ngOnInit() {
      await this.fetchContent();  
      interval(5000) // Atualiza a cada 5 segundos (5000 milissegundos)
      .subscribe(() => {
      this.fetchContent();
        }
      );
  }

  public fetchContent(): void {
    if (!this.auth.isAuthenticated())
      return;
    this.loading = true;
    this.service.getArquivos().subscribe({
      next: (payload: ProcessamentoArquivo[]) => {
        this.processamentos = payload;
    },

      error: (error: HttpErrorResponse) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Processamento Arquivos',
          detail: 'Erro ao tentar buscar lista de arquivos!',
          life: this.lifeMessageToast,
        });
        console.error('Erro: ' + error);
      },
    });
    this.loading = false;
  }

  detalhaArquivo(processamento: ProcessamentoArquivo) {
    this.sharedData.setProcessamentoArquivo(processamento.id);
    const url = '/lista'; 
    window.open(url, '_blank');
  }

  getIconClass(status: string): String | undefined | null {
    if (status == 'CONCLUIDO')
      return 'pi pi-check p-button-success';
    if (status == 'FALHA')
      return 'pi pi-times p-button-danger';
    if (status == 'AVISOS')
      return 'pi pi-exclamation-triangle p-button-warning';
    if (status == 'PENDENTE')
      return 'pi pi-stopwatch p-button-help';
    if (status == 'EM_ANDAMENTO')
      return 'pi pi-sync pi-spin p-button-info';  
    return '';
  }

  upload() {
    this.uploadDialog = true;

  }

  //dialog do botão de anexo
  uploadFile(event: any) {
    this.loading = true;
    try {
      for (let file of event.files) {
        this.service.uploadFile(file).subscribe({
          next: (response) => {
            console.log('Status: ' + response.status);
              this.messageService.add({
                severity: 'success',
                summary: 'Upload',
                detail: 'Upload concluido;',
                life: this.lifeMessageToast,
              });
              this.fileUpload.clear();
              this.uploadDialog = false;
              this.fetchContent();
            //}
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Upload',
              detail: 'Erro: ' + err.error,
              life: this.lifeMessageToast,
            });
          },
        });
      }
    } catch (error) {
      this.messageService.add({
        severity: 'error',
        summary: 'Upload',
        detail: 'Erro ao tentar fazer Upload!',
        life: this.lifeMessageToast,
      });
    }
    this.loading = false;
    this.fileUpload.clear();
    this.uploadDialog = false;
    this.fetchContent();
  }


}