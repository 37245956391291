<app-menu-bar></app-menu-bar>
<div class="dashboard-container">
    <table>
        <tr width="100%">
            <th width="33%">Mensal</th>
            <th width="33%">Mes Anterior</th>
            <th width="33%">Mes Atual</th>
        </tr>
        <tr>
            <td><p-chart height="300px" type="line" [data]="qtdCargaMesData" [options]="qtdCargaMesOptions"/></td>
            <td><p-chart height="300px" type="bar" [data]="colunasMesAnteriorData" [options]="colunasMesOptions"/></td>
            <td><p-chart height="300px" type="bar" [data]="colunasMesAtualData" [options]="colunasMesOptions"/></td>
        </tr>
        <tr>
            <td><p-chart height="300px" type="line" [data]="qtdRegistrosMesData" [options]="qtdRegistrosMesOptions"/></td>
            <td><p-chart height="300px" type="pie" [data]="regStatusMesAnteriorData" [options]="regStatusOptions"/></td>
            <td><p-chart height="300px" type="pie" [data]="regStatusMesAtualData" [options]="regStatusOptions"/></td>
        </tr>
        <tr>
            <td><p-chart height="300px" type="line" [data]="qtdDocumentosMesData" [options]="qtdDocumentosMesOptions"/></td>
            <td><p-chart height="300px" type="pie" [data]="docStatusAnteriorData" [options]="docStatusOptions"/></td>
            <td><p-chart height="300px" type="pie" [data]="docStatusAtualData" [options]="docStatusOptions"/></td>
        </tr>
    </table>
</div>
